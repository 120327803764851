import axios from "axios"

import { baseObjActivity, baseObjCombat, baseObjFarming, baseObjInventory, baseObjStats, baseObjUpgrades } from "./baseObjects"

const API_URI_TEST = "https://localhost:3001"
const API_URI = "https://api.goosebanana.com"

const API_TO_USE = API_URI

//Selector for getting whole current state
export function getState(state) {
    return state
}

export const getUserSaveFromCloud = async (uuid = (localStorage.getItem("uuid") ?? "")) => {

    return new Promise((resolve, reject) => {

        axios.get(API_TO_USE + "/saves/load", {
            params: {
                'uuid': uuid
            }
        }
        ).then((res) => {
            console.log(res)
            resolve(res.data)
        }
        ).catch((err) => {
            console.log(err)
            resolve(null)
        }
        ).finally(() => {

        })

    })

}

const handleCloudSave = (playerData) => {

    let lastCloudSaveTimestamp = localStorage.getItem("lastCloudSave") ?? "0"

    console.log(Date.now(), lastCloudSaveTimestamp, Date.now() - parseInt(lastCloudSaveTimestamp))

    if (Date.now() - parseInt(lastCloudSaveTimestamp) > 1000 * 15) {

        console.log("Cloud saving...")

        axios.post(API_TO_USE + "/saves/save", {
            'uuid': localStorage.getItem("uuid"),
            'saveData': playerData
        }
        ).then(async (res) => {

            console.log(res)
            localStorage.setItem("lastCloudSave", Date.now().toString())

        }).catch((err) => {
            console.log(err.message)
            console.log(err.code)

        }).finally(() => {
            //res.send("EEE")
        })

    } else {

        console.log(`Saved too recently, wait ${toDecimalPlace(30 - ((Date.now() - parseInt(lastCloudSaveTimestamp)) / 1000), 1)} more seconds`)

    }

}

//Save playerData and current timestamp to localStorage
export function savePlayerData(playerData, saveSlot = 1, isCloudSave = false) {
    //var lastSave = localStorage.getItem('lastSaveTime')
    //if (Date.now() - ((typeof lastSave == "string") ? parseInt(lastSave) : 0) <= 3000) return

    //playerData = {...playerData, inventory: JSON.stringify(Array.from(playerData.inventory))}

    //localStorage.setItem('playerData', JSON.stringify(playerData))
    //localStorage.setItem('lastSaveTime', Date.now().toString())

    playerData = {
        ...playerData,
        lastSaveTime: Date.now()
    }

    console.log("savePlayerData called", saveSlot, playerData, isCloudSave)

    if (isCloudSave) {

        handleCloudSave(playerData)

        return
    }



    let savedPlayerData = JSON.parse(localStorage.getItem("saveData") ?? "{}")
    console.log(savedPlayerData)
    savedPlayerData = {
        ...(savedPlayerData),
        [saveSlot]: {
            ...playerData
        }
    }
    console.log(savedPlayerData)
    localStorage.setItem("saveData", JSON.stringify(savedPlayerData))
}

export const createBaseCharacterSave = () => {

    return {
        activity: baseObjActivity,
        combat: baseObjCombat,
        farming: baseObjFarming,
        inventory: baseObjInventory,
        stats: baseObjStats,
        upgrades: baseObjUpgrades,
        lastSaveTime: Date.now()
    }

}

//Return saved player data from localStorage
export const loadPlayerData = () => {

    /*let lsSaveData: any
    lsSaveData = localStorage.getItem('playerData')
    console.log(JSON.parse(lsSaveData))
    if (lsSaveData !== null) {
        lsSaveData = JSON.parse(lsSaveData)
        console.log(lsSaveData)
    }*/

    let saveData: any = localStorage.getItem('saveData')

    if (saveData !== null) {
        return JSON.parse(saveData)
    }

    saveData = {
        1: (localStorage.getItem('playerData') && saveData === null) ? {
            ...(JSON.parse(localStorage.getItem('playerData') || "")),
            lastSaveTime: Date.now()
        } : {},
        2: {},
        3: {}
    }

    console.log(saveData)

    localStorage.setItem('saveData', JSON.stringify(saveData))

    return saveData

}

export const randIntInterval = (min, max) => {
    if (min > max) {
        let temp = min
        min = max
        max = temp
    }
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const mergeObjects = (obj1, obj2) => {

    let newObj = { ...obj1 }

    for (var i in obj2) {

        newObj = { ...newObj, [i]: (newObj[i]) ? obj1[i] + obj2[i] : obj2[i] }

    }

    return newObj

}

export const createLoot = (item, minAmount = 1, maxAmount?) => {
    return { item: item, minAmount: minAmount, maxAmount: (maxAmount) ? maxAmount : minAmount }
}

export const createRollableLoot = (item, weight, minAmount = 1, maxAmount?) => {
    return { item: item, weight: weight, minAmount: minAmount, maxAmount: (maxAmount) ? maxAmount : minAmount }
}

export const formatMoney = (moneyAmount) => {

    if (moneyAmount < 10000) {
        return moneyAmount.toLocaleString("en-GB")
    }

    if (moneyAmount < 10000000) {
        return (Math.floor(moneyAmount / 1000).toLocaleString("en-GB") + "K")
    }

    return (Math.floor(moneyAmount / 1000000).toLocaleString("en-GB") + "M")

}

export const toDecimalPlace = (number, dp) => {
    return Math.round(number * Math.pow(10, dp)) / Math.pow(10, dp)
}

export const getDiscordInfo = async (uuid) => {

    return new Promise((resolve, reject) => {

        axios.get(API_TO_USE + "/discord/me", {
            params: {
                'uuid': localStorage.getItem("uuid")
            }
        }
        ).then((res) => {
            console.log(res)
            resolve(res.data)
        }
        ).catch((err) => {
            console.log(err)
            resolve(null)
        }
        ).finally(() => {

        })

    })

}

export const verifyDiscordCode = async (code, devMode = 0) => {

    return new Promise((resolve, reject) => {
        axios.get(API_TO_USE + "/discord/login", {
            params: {
                'code': code,
                'uuid': localStorage.getItem("uuid"),
                'redirIndex': devMode
            }
        }
        ).then(async (res) => {
            //console.log(res.data)
            //Cookies.set("discordLogin", JSON.stringify(res.data))

            resolve(res.data)

        }).catch((err) => {
            console.log(err)
            resolve(null)
        })
    })
}